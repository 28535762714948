<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Username"
    style="width: 300px"
    allow-clear
    show-search
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (state.search = null)"
    :size="size"
    :disabled="disabled"
    :show-arrow="true"
    :loading="state.loading">
    <a-select-option v-for="d in state.data" :key="d.id" :label="d.username">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.username)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
  props: {
    value: { type: [Array, Number], default: null },
    mode: {
      default: 'multiple',
      type: String,
    },
    size: {
      default: null,
      type: String,
    },
    disabled: [Boolean],
  },
  emits: ['update:value'],
  setup(props) {
    const state = reactive({
      loading: false,
      data: [],
      search: '',
    })

    const fetchData = q => {
      state.loading = true

      apiClient.get('/api/filter/username', { params: { q } })
        .then(({ data }) => {
          state.data = data
        })
        .finally(() => {
          state.loading = false
        })
    }
    onMounted(() => {
      fetchData()
    })

    const onSearch = debounce(value => {
      state.search = value
      fetchData(value)
    }, 500)

    const highlight = value => {
      return value.replace(new RegExp(state.search, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return { state, valueState: useVModel(props, 'value'), highlight, onSearch }
  },
}
</script>

<style></style>
